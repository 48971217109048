'use client';

import React, { createContext, useContext } from 'react';
import { type Global } from '@/tina/__generated__/types';

type GlobalContextProps = Partial<Global>;

const GlobalProviderContext = createContext<GlobalContextProps | null>(null);
GlobalProviderContext.displayName = 'GlobalProviderContext';

interface GlobalProviderProps extends Partial<Global> {
  children: React.ReactNode;
}

// import { GlobalProvider } from "path-to-context/GlobalProviderContext"
// use <GlobalProviderProvider> as a wrapper around the part you need the context for
function GlobalProvider({
  children,
  ...globalProps
}: GlobalProviderProps): React.JSX.Element {
  const { navigation, social } = globalProps;

  return (
    <GlobalProviderContext.Provider value={{ navigation, social }}>
      {children}
    </GlobalProviderContext.Provider>
  );
}

// import { useGlobal } from "path-to-context/GlobalProviderContext"
// within functional component
// const { sessionToken, ...GlobalProviderContext } = useGlobal()
function useGlobal(): GlobalContextProps {
  const context = useContext(GlobalProviderContext);

  if (!context) {
    throw new Error('You should use useGlobal within an GlobalProviderContext');
  }

  return context;
}

export { GlobalProvider, useGlobal };
