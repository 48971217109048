import(/* webpackMode: "eager", webpackExports: ["GlobalProvider"] */ "/app/apps/about/src/app/(base)/global-provider.tsx");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/image-component.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/dist/client/link.js");
;
import(/* webpackMode: "eager" */ "/app/node_modules/next/font/google/target.css?{\"path\":\"src/app/(base)/layout.tsx\",\"import\":\"Inter\",\"arguments\":[{\"subsets\":[\"latin\"]}],\"variableName\":\"inter\"}");
;
import(/* webpackMode: "eager" */ "/app/apps/about/src/app/(base)/globals.css");
;
import(/* webpackMode: "eager", webpackExports: ["default"] */ "/app/packages/announcement/src/bar.tsx");
;
import(/* webpackMode: "eager" */ "/app/packages/ui/src/images/logo-simple.png");
